import { Box, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import UIHeading from "../UIComponents/UIHeading";
import UIHeadingGrad from "../UIComponents/UIHeadingGrad";
import UISubhead from "../UIComponents/UISubhead";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

const About = () => {
  return (
    <Box position="relative" zIndex="1" overflow="hidden" h="80vh">
      <AnimationOnScroll animateIn="animate__bounceIn" animateOnce={true}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <SimpleGrid
            w={["90%", "90%", "80%"]}
            h="80%"
            position="relative"
            py={["10%", "10%", "5%"]}
            px={["7%", "7%", "0"]}
            templateColumns={["auto", "auto", "40% 60%"]}
            border="1px solid #ffffff33"
            borderRadius="xl"
            bg="rgba(255, 255, 255, 0.05);"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              w="1000px"
              h="1000px"
              right={["-100", "-150", "-150"]}
              top={["-100", "-270", "-270"]}
              opacity="0.1"
              position="absolute"
              bg="radial-gradient(41.76% 41.76% at 52.51% 51.69%, #A0506C 0%, rgba(191, 9, 255, 0) 100%)"
            />
            <Box >
              <Image
                src={
                  "https://drive.google.com/uc?id=18rqiKCVOgpa8Tq415H9BPUpI9-0PSOIC"
                }
                alt="aboutImg"
                // top="-35%"
                top={['0px', '0%', '-25%', '-34%']}
                display={["none", "none", "block"]}
                position="absolute"
                zIndex={1}
                w="40%"
                left="0%"
              />
            </Box>
            <Box mr={["0", "0", "20"]}>
              <UIHeading text="WHAT IS" />
              <UIHeadingGrad text="FLURT NETWORK? " />
              <UISubhead
                text="Flurt Network is a Dating & Networking social platform. It can be accessed only using soon to launch Flurt App"
              />
              <UISubhead text="Exclusive opportunities available In-App & In-Real-Life !" />
            </Box>
          </SimpleGrid>
        </Box>
      </AnimationOnScroll>
    </Box>
  );
};

export default About;
