import { Box, Image } from "@chakra-ui/react";
import React from "react";
import patternLeft from "../images/patternLeft.svg";
import patternRight from "../images/patternRight.svg";
import Hero from "../Components/Hero";
import About from "../Components/About";
import HowToBecome from "../Components/HowToBecome";
import Roadmap from "../Components/Roadmap";
import AllAboutNfts from "../Components/AllAboutNfts";
import QuickLinks from "../Components/QuickLinks";
import FAQSection from "../Components/FAQSection";
import NewLinks from "../Components/NewLinks";


const Home = () => {
  return (
    <Box backgroundColor={["#15141F"]}>
      <Image
        src={patternLeft}
        alt="patternLeft"
        position="absolute"
        left="-10%"
      />
      <Image
        src={patternRight}
        alt="patternLeft"
        position="absolute"
        right={0}
      />
      <Hero />
      <About />
      <NewLinks />
      <HowToBecome />
      <Roadmap />
      <AllAboutNfts />
      <QuickLinks />
      <FAQSection />
    </Box>
  );
};

export default Home;
