import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import UIHeading from "../UIComponents/UIHeading";
import UIHeadingGradSameLine from "../UIComponents/UIHeadingGradSameLine";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { RiFilePaper2Line } from "react-icons/ri";
import UIButton from "../UIComponents/UIButton";
import { AnimationOnScroll } from "react-animation-on-scroll";
//import Boxlink from "./quicklinkbox";
//import { motion } from 'framer-motion'
/*
const animationKeyframes = keyframes`

  0% { transform: scale(0.1) rotate(0); border-radius: 2%; }
  10% { transform: scale(0.25) rotate(0); border-radius: 22%; }
  30% { transform: scale(0.5) rotate(0); border-radius: 42%; }
  50% { transform: scale(0.75) rotate(0); border-radius: 62%; }
  70% { transform: scale(1) rotate(0); border-radius: 82%; }
  90% { transform: scale(1.5) rotate(0); border-radius: 100%; }
  100% { transform: scale(0.5) rotate(0); border-radius: 100%; }
`;

*/
//const animation = `${animationKeyframes} 2s ease-in-out infinite`;

const QuickLinks = () => {
  const links = [
    {
      img: <RiFilePaper2Line style={{ color: "white" }} size="100" />,
      link: "https://drive.google.com/file/d/1KUG0aBWAOkk9X_q9nHZWDU6ASkdmWoKr/view?usp=sharing",
      text: "Whitepaper",
      cta: "Download",
    },
    {
      img: <FaDiscord style={{ color: "white" }} size="100" />,
      link: "https://discord.gg/xVuhKewtdA",
      text: "Discord Community",
      cta: "Join Now",
    },
    {
      img: <FaTwitter style={{ color: "white" }} size="100" />,
      link: "https://www.twitter.com/flurtnet",
      text: "Twitter Community",
      cta: "Join Now",
    },
  ];
  return (
    <Box mt="20" px="10%" position="relative" overflowX="hidden">
      <AnimationOnScroll animateIn="animate__bounceIn" animateOnce={true}>
        <Box display="flex" justifyContent="center" position="relative">
          <UIHeading text="QUICK" />
          <Box mx="1" />
          <UIHeadingGradSameLine text="LINKS" />
        </Box>
      </AnimationOnScroll>
      <SimpleGrid
        templateColumns={["auto", "auto", "33% 33% 33%"]}
        spacing="6"
        py="20"
      >
        {links.map((data) => (
          <AnimationOnScroll animateIn="animate__flipInX" animateOnce={true}>
            <Box
              bg="rgba(255, 255, 255, 0.05);"
              py="10"
              h="100%"
              borderRadius="8"
              position="relative"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              alignItems="center"
              border="1px solid #ffffff33"
            >
              <Box
                w="1000px"
                h="1000px"
                left="-80"
                top={["-260", "-200", "-200"]}
                opacity="0.05"
                position="absolute"
                bg="radial-gradient(41.76% 41.76% at 52.51% 51.69%, #A0506C 0%, rgba(191, 9, 255, 0) 100%)"
                display="flex"
                z-index="1"
                transform="translateY(100px)"
              />
              {data.img}
              <Text color="white" my="10" fontSize={20}>
                {data.text}
              </Text>
              <Box position="relative">
                <a href={data.link} target="_blank" rel="noreferrer">
                  <UIButton text={data.cta} />
                </a>
              </Box>
            </Box>
          </AnimationOnScroll>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default QuickLinks;
