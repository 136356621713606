import { Text } from "@chakra-ui/react";
import React from "react";

const UISubhead = ({ text, noMargin, roadMapText, responsive }) => {
  return (
    <Text
      color="white"
      mt={noMargin ? ["0", "0", "0"] : ["6", "10", "10"]}
      fontSize={responsive ? ["4vw"] : roadMapText ? ["1vw"] : ["1em", "1em", "1.2em", "1.2em"]}
      letterSpacing="0.5px"
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
};

export default UISubhead;
