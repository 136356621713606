import { useLayoutEffect } from "react";
import "./App.css";
import { Box, ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/darkTheme.js";
import Navbar from "./Components/Navbar";
import Home from "./screens/Home";
import Footer from "./Components/Footer";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TnC from "./screens/TnC";
import { Route, Routes, useLocation } from "react-router-dom";
import Whitepaper from "./screens/Whitepaper";


function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <Box>
      <ChakraProvider theme={theme}>
        <Wrapper>
          <Box bg="black">
            <Box position="absolute" w="100%">
              <Navbar />
            </Box>
            <Routes>
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="tnc" element={<TnC />} />
              <Route path="/" element={<Home />} />
              <Route
                path="whitepaper"
                element={<Whitepaper type="whitepaper" />}
              />
              <Route
                path="litepaper"
                element={<Whitepaper type="litepaper" />}
              />
              <Route
                path="pitchdeck"
                element={<Whitepaper type="pitchdeck" />}
              />
            </Routes>
            <Footer />
          </Box>
        </Wrapper>
      </ChakraProvider>
    </Box>
  );
}

export default App;
