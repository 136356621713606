import { Box } from "@chakra-ui/react";
import React from "react";

const Whitepaper = ({ type }) => {
  return (
    <Box
      padding="5% 0 2% 0"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <object
        data={
          {
            whitepaper: "whitepaper.pdf",
            litepaper: "litepaper.pdf",
            pitchdeck: "pitchdeck.pdf",
          }[type]
        }
        type="application/pdf"
        width="90%"
        height="100%"
      >
        <embed
          src={
            {
              whitepaper: "whitepaper.pdf",
              litepaper: "litepaper.pdf",
              pitchdeck: "pitchdeck.pdf",
            }[type]
          }
          type="application/pdf"
        />
      </object>
    </Box>
  );
};

export default Whitepaper;
