import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  semanticTokens: {
    colors: {
      error: "red.500",
      success: "green.500",
      primary: {
        default: "#AA1240",
        _dark: "#404040",
      },
      secondary: {
        default: "#E94A81",
        _dark: "#404040",
      },
    },
  },
  fonts: {
    heading: "Mont-Bold",
    body: "Poppins",
  },
});

export default theme;
