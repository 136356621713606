import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import UISubhead from "../UIComponents/UISubhead";

const FAQCard = ({ q, a, type }) => {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        onClick={() => setIsHidden(!isHidden)}
      >
        <Box flexGrow={1} textAlign="left" py="5" cursor="pointer">
          <Text color="white" fontSize={["xl", "xl", "2xl"]} fontWeight={600}>
            {q}
          </Text>
        </Box>

        {isHidden ? (
          <AiFillPlusCircle
            style={{
              color: "white",
            }}
            size={25}
          />
        ) : (
          <AiFillMinusCircle
            style={{
              color: "white",
            }}
            size={25}
          />
        )}
      </Box>
      {!isHidden && (
        <Box textAlign="left" pb="10" pt="3">
          {type === "bullets" ? (
            <>
              <UISubhead text={a.title} noMargin={true} />
              {a.desc.map((perk) => (
                <SimpleGrid templateColumns="20px auto" my={["6", "6", "5"]}>
                  <div className="Box">
                    <Box
                      h={["5px", "10px", "10px"]}
                      w={["5px", "10px", "10px"]}
                      mt={["7px"]}
                      boxShadow="#C72891cc 0px 0px 30px 0px"
                      bg="linear-gradient(124.24deg, #C72891 -71.24%, #012ECE -71.22%, #89F8FF 14.01%, #C72891 102.89%)"
                      borderRadius="full"
                    />
                  </div>

                  <Box ml={["0", "0", "2"]}>
                    <UISubhead
                      text={perk}
                      noMargin={true}
                      roadMapText={false}
                    />
                  </Box>
                </SimpleGrid>
              ))}
            </>
          ) : (
            <UISubhead text={a} noMargin={true} />
          )}
        </Box>
      )}
      <Box h="1px" w="100%" bg="#ffffff44" />
    </Box>
  );
};

export default FAQCard;
