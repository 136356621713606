import React from "react";
//import Carousel from "fade-carousel";

import { Box, Image } from "@chakra-ui/react";
//import { Carousel } from "3d-react-carousal";
import "animate.css/animate.min.css";
//import mint from '../images/Gif-2.gif';

const CarouselSection = () => {


  return (
    <Box h={["", "70vh", "65vh"]} left={'3%'} right={"20%"} top={"-2%"} bottom={"2%"} position="relative">
      <Box top="1%" mt="-100" bottom="40%">
        <Image width={"450"} height={"580"}
          objectFit='cover'
          src={'/iPhoneV3.png'} alt="1"
          py={10} />,
      </Box>
    </Box>
  );
};

export default CarouselSection;
