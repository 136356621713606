import { Box, SimpleGrid, Image, VStack } from "@chakra-ui/react";
import React from "react";
import UIHeading from "../UIComponents/UIHeading";
import UIHeadingGradSameLine from "../UIComponents/UIHeadingGradSameLine";
/*
import muscle from "../images/potion-showcase/muscle.png";
import sorcery from "../images/potion-showcase/sorcery.png";
import charm from "../images/potion-showcase/charm.png";
import speed from "../images/potion-showcase/speed.png";
import vision from "../images/potion-showcase/vision.png";
import wit from "../images/potion-showcase/wit.png";
import chef from "../images/chef.png";
import doctor from "../images/doctor.png";
import fireFighter from "../images/fireFighter.png";
import patrol from "../images/patrol.png";
import placeholder from "../images/placeholder.jpeg";
*/
//import NFT from "./NFT";
import BulletLayout from "../UIComponents/BulletLayout";
import UIBoldSubhead from "../UIComponents/UIBoldSubhead";
// import UIButton from "../UIComponents/UIButton";
import deck from "../images/bannerv5.png";
//import legacy from '../images/Landscape.mp4';
import { AnimationOnScroll } from "react-animation-on-scroll";
//import characters from '../images/Characters.gif'
//import ReactPlayer from 'react-player';

const AllAboutNfts = () => {
  const items = [
    // "There are 6 types of Legacy Potions – Muscle, Vision, Speed, Sorcery, Charm & Wit",
    // "Each type of potion offers its holder a unique and interesting power in Flurt Network",
    // "Potion NFTs are powers passed down to you by the founders as a legacy",
    "Physical (Physical+Digital) Private NFT",
    "For access to Parties,Events,Bootcamps & more",
    "Claim #imaflurt NFT that looks like you"
  ];
  const finuitems = [
    "Only available to VIP Flurt Card holders",
    "Custom Character NFT that looks like You",
    "Gain Superpower in the flurt App",
    "Legal Rights to use your character",
  ];
  return (
    <VStack>
      <Box height={{ base: "21vh", md: "46vh", lg: "75vh" }}>
        <Image
          src={deck}
          alt="patternLeft"
          position="relative"
          bottom={"10%"}
          zIndex={0}
        />
      </Box>

      <Box mt="20" position="relative" overflowX="none">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
          <Box
            display="flex"
            justifyContent="center"
            position="relative"
            overflowX="none"
            flexDirection={["column", "column", "row"]}
            textAlign={["center", "center", "left"]}
          >
            <UIHeading text="ALL ABOUT" />
            <Box mx="1" />
            <UIHeadingGradSameLine text="OUR NFTS" />
          </Box>
        </AnimationOnScroll>

        <SimpleGrid
          templateColumns={["auto", "auto", "45% 55%"]}
          px="10%"
          py="5%"
          spacing="14"
          alignItems="center"
        >
          <Box mt="10">
            <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
              <UIBoldSubhead text="Get the VIP Flurt Card" />
            </AnimationOnScroll>

            <BulletLayout items={items} />
          </Box>
          <Box position="relative" left={"0%"}>
            <video
              muted
              autoPlay
              loop
              src={
                "/vipcard.mp4"
                // "https://drive.google.com/uc?id=1IL_zE2u63bi1RB22ROl347Y1Z09jDtGT"
                // "https://drive.google.com/uc?export=view&id=1GzdKJccsKawAAJH0h0H6HT7Uy4GSy9eO"
              }
            />
          </Box>
        </SimpleGrid>
        <SimpleGrid
          templateColumns={["auto", "auto", "45% 55%"]}
          px="10%"
          py="5%"
          spacing="14"
          position="relative"
          zIndex="10"
          alignItems="center"
        >
          <Box mt="0">
            <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
              <UIBoldSubhead text="#imaflurtNFTs" />
            </AnimationOnScroll>

            <BulletLayout items={finuitems} />
            {/* <AnimationOnScroll
              animateIn="animate__shakeX"
              animateOnce={true}
              initiallyVisible={true}
            >
              <a
                href="https://whitelist.flurt.net"
                target="_blank"
                rel="noreferrer"
              >
                <UIButton text="JOIN WHITELIST" />
              </a>
            </AnimationOnScroll> */}
          </Box>

          <Box
            h={["65vh", "70vh", "65vh"]}
            left={"0%"}
            right={"20%"}
            top={"0%"}
            position="relative"
          >
            <Box top="10%" mt="-100" bottom="90%">
              <Image
                boxSize={"700"}
                objectFit="cover"
                src={
                  "https://drive.google.com/uc?id=1lCs2gXjNZkxjIM83yKCwz0-itKO1A9Sr"
                }
                alt="1"
                py={20}
              />
              ,
            </Box>
          </Box>
        </SimpleGrid>
      </Box>
    </VStack>
  );
};

export default AllAboutNfts;
