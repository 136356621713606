import { Box, Image, SimpleGrid } from "@chakra-ui/react";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import heroBg from "../images/heroBg.png";
import UIHeading from "../UIComponents/UIHeading";
// import UIHeadingGrad from "../UIComponents/UIHeadingGrad";
import UISubhead from "../UIComponents/UISubhead";
import CarouselSection from "./Carousel";

const Hero = () => {
  const headRef = useRef();
  const subheadRef = useRef();
  const btnRef = useRef();
  useEffect(() => {
    gsap
      .timeline()
      .from(
        headRef.current,
        {
          autoAlpha: 0,
          y: 20,
        },
        0.5
      )
      .to(
        headRef.current,
        {
          autoAlpha: 1.0,
          y: 0,
        },
        1
      )
      .from(
        subheadRef.current,
        {
          autoAlpha: 0,
          y: 20,
        },
        0.9
      )
      .to(
        subheadRef.current,
        {
          autoAlpha: 1.0,
          y: 0,
        },
        1.4
      )
      .from(
        btnRef.current,
        {
          autoAlpha: 0,
          y: 20,
        },
        1.0
      )
      .to(
        btnRef.current,
        {
          autoAlpha: 1.0,
          y: 0,
        },
        1.6
      );
  }, []);
  return (
    <Box h={["auto", "auto", "110vh"]}>
      <Image
        src={heroBg}
        alt="heroBg"
        w="100%"
        opacity={0.4}
        h={["auto", "auto", "100vh"]}
        objectFit="none"
        position="absolute"
      />
      <SimpleGrid
        h="100%"
        pt={["150", "150", "0"]}
        position="relative"
        zIndex="1"
        templateColumns={["auto", "auto", "55% 45%"]}
        alignItems="center"
        justifyContent="center"
      >
        <Box ml={["7%", "10%", "15%"]} mr={["7%", "7%", "0"]}>
          <Box ref={headRef}>
            <UIHeading text="Find Meaningful Connections On Flurt Network" />
            {/* <UIHeadingGrad text="(instead of random AI art)" /> */}
          </Box>

          <Box ref={subheadRef}>
            <UISubhead text="Dating,Networking,Parties,Collab,Events & more..." />
          </Box>
          {/* 
          <Box ref={btnRef}>
            <a href="https://opensea.io/flurt" target="_blank" rel="noreferrer">
              <UIButton text="CHECKOUT IN OPENSEA" includeMargin={true} />
            </a>
            <Image boxSize={"100"}
              objectFit='cover'
              src={'/ios.png'} alt="1"
              py={20} />,
          </Box> */}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img src="/ios.png" alt="ji" width={"150px"} height="70px" style={{ marginTop: "25px" }} />
            <img src="/androidicon.png" alt="ji" width={"160px"} height="70px" style={{ marginTop: "25px", marginLeft: "20px" }} />
          </div>
          {/* <Box>
            <img src="/androidicon.png" alt="ji" width={"200px"} height="70px" style={{ marginTop: "20px" }} />
          </Box> */}
        </Box>

        <Box style={{ marginBottom: "2%" }}>
          <CarouselSection />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Hero;
