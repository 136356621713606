import { Box, Heading, SimpleGrid, Text, keyframes } from "@chakra-ui/react";
import React from "react";
import {
  BsTwitter,
  BsDiscord,
  BsTelegram,
  BsFacebook,
  BsInstagram,
  BsMedium,
  BsYoutube,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import UIButton from "../UIComponents/UIButton";

const animationKeyframes = keyframes`
0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
`;

const animation = `${animationKeyframes} 2s ease-in-out infinite`;

const Footer = () => {
  const socials = [
    {
      img: <BsTwitter style={{ color: "#fff" }} size={20} />,
      link: "https://twitter.com/flurtnet",
    },
    {
      img: <BsDiscord style={{ color: "#fff" }} size={20} />,
      link: "https://discord.gg/xVuhKewtdA",
    },
    {
      img: <BsTelegram style={{ color: "#fff" }} size={20} />,
      link: "https://t.me/flurtnet",
    },
    {
      img: <BsInstagram style={{ color: "#fff" }} size={20} />,
      link: "https://www.instagram.com/flurtnet",
    },
    {
      img: <BsMedium style={{ color: "#fff" }} size={20} />,
      link: "https://flurtnet.medium.com/",
    },
    {
      img: <BsYoutube style={{ color: "#fff" }} size={20} />,
      link: "https://www.youtube.com/channel/UCrjlF7cPKmx3Aoqoeninbzw",
    },
    {
      img: <BsFacebook style={{ color: "#fff" }} size={20} />,
      link: "https://www.facebook.com/flurtnetwork",
    },
  ];

  return (
    <Box>
      <Box
        w="100%"
        h="4px"
        bg="linear-gradient(90.13deg, #C72891 -1.4%, #012ECE -1.39%, #89F8FF 48.24%, #C72891 100%)"
        animation={animation}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <SimpleGrid
          templateColumns={["repeat(4, 25%)", "repeat(7, auto)"]}
          my="10"
        >
          {socials.map((social) => (
            <Box m="4">
              <a href={social.link} target="_blank" rel="noreferrer">
                {social.img}
              </a>
            </Box>
          ))}
        </SimpleGrid>

        <Heading color="white">Don't miss out!</Heading>
        <Text
          color="white"
          fontSize={["lg", "xl", "xl"]}
          my="6"
          mx="10"
          textAlign="center"
        >
          Get your "Flurt in You" NFTs that look like you.
        </Text>
        <AnimationOnScroll
          animateIn="animate__shakeX"
          animateOnce={true}
          initiallyVisible={true}
        >
          <a
            href="https://whitelist.flurt.net"
            target="_blank"
            rel="noreferrer"
          >
            <UIButton text="JOIN WHITELIST" />
          </a>
        </AnimationOnScroll>
      </Box>
      <Box
        w="100%"
        py="4"
        mt="10"
        px="30"
        display="flex"
        flexDirection={["column", "row", "row"]}
        className="grad"
        bg={[
          "linear-gradient(90.13deg, #C72891 -1.4%, #012ECE 100%)",
          "linear-gradient(90.13deg, #C72891 -1.4%, #012ECE -1.39%, #C72891 100%)",
          "linear-gradient(90.13deg, #C72891 -1.4%, #012ECE -1.39%, #89F8FF 48.24%, #C72891 100%)",
        ]}
      >
        <Box flexGrow={1} my={["4", "4", "0"]}>
          <Text color="white" letterSpacing="0.5px" fontWeight={500}>
            2022 All Rights Reserved, Flurt Net
          </Text>
        </Box>
        {[
          { text: "Privacy Policy", link: "/privacy-policy" },
          { text: "Terms and Conditions", link: "/tnc" },
        ].map((item) => (
          <Link to={item.link}>
            <Text
              color="white"
              letterSpacing="0.5px"
              mx={["0", "0", "5"]}
              my={["1", "1", "0"]}
            >
              {item.text}
            </Text>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default Footer;
