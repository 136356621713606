import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import BulletLayout from "../UIComponents/BulletLayout";
import UIButton from "../UIComponents/UIButton";
import UIHeading from "../UIComponents/UIHeading";
import UIHeadingGrad from "../UIComponents/UIHeadingGrad";
import UISubhead from "../UIComponents/UISubhead";

const HowToBecome = () => {
  const perks = [
    {
      title: "$FLURT token holders get:",
      points: [
        "Tier wise access to Flurt App & its features.",
        "High APY (annual interest) on staking tokens.",
        "Free 10 Heart NFTs every month against staked tokens.",
      ],
    },
    {
      title: "Flurt NFT holders get:",
      points: [
        "Invites to exclusive parties, events, bootcamps & more.",
        "Access to our collaborative spaces such as Flurt Cafe, Flurt Bars & Flurt House.",
        "Free Access to 3rd Tier in Flurt App without holding tokens",
      ],
    },
  ];
  return (
    <Box
      position="relative"
      zIndex="1"
      p="10%"
      mt={["10%", "10%", "0"]}
      overflow="hidden"
    >
      <Box
        w="1000px"
        h="1000px"
        left="-80"
        top={["-150", "-200", "-200"]}
        opacity="0.3"
        position="absolute"
        filter={"blur(300px)"}
        bg="radial-gradient(45.02% 45.02% at 52.51% 51.69%, rgba(199, 40, 145, 0.52) 0%, rgba(191, 9, 255, 0) 100%)"
      />
      <SimpleGrid
        alignItems="center"
        templateColumns={["auto", "auto", "50% 50%"]}
      >
        <Box mb={["14", "10", "0"]} position="relative">
          <UIHeading text="BENEFITS OF BECOMING" />
          <UIHeadingGrad text="A FLURT MEMBER" />
          <UISubhead text="All NFT holders gain permanent access to the Flurt App." />
          <AnimationOnScroll
            animateIn="animate__shakeX"
            animateOnce={true}
            initiallyVisible={true}
          >
            <a href="https://opensea.io/flurt" target="_blank" rel="noreferrer">
              <UIButton text="BECOME A MEMBER" includeMargin={true} />
            </a>
          </AnimationOnScroll>
        </Box>
        <Box>
          {perks.map((perk) => (
            <Box key={perk.title}>
              <AnimationOnScroll animateIn="animate__zoomIn" animateOnce={true}>
                <br />
                <Box fontWeight={600}>
                  <UISubhead
                    text={perk.title}
                    noMargin={true}
                    roadMapText={false}
                  />
                </Box>
              </AnimationOnScroll>

              <BulletLayout items={perk.points} />
            </Box>
          ))}
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default HowToBecome;
