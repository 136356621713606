import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import UISubhead from "./UISubhead";

const BulletLayout = ({ items, roadMapBullets, responsive }) => {
  return (
    <Box>
      {items.map((perk) => (
        <AnimationOnScroll animateIn="animate__zoomIn" animateOnce={true}>
          <SimpleGrid templateColumns="20px auto" my={["6", "6", "5"]}>
            <div className="Box">
              <Box
                h={["5px", "10px", "10px"]}
                w={["5px", "10px", "10px"]}
                mt={["7px"]}
                boxShadow="#C72891cc 0px 0px 30px 0px"
                bg="linear-gradient(124.24deg, #C72891 -71.24%, #012ECE -71.22%, #89F8FF 14.01%, #C72891 102.89%)"
                borderRadius="full"
              />
            </div>

            <Box ml={["0", "0", "2"]}>
              {roadMapBullets ? (
                <UISubhead
                  text={perk}
                  noMargin={true}
                  roadMapText={true}
                  responsive={responsive}
                />
              ) : (
                <UISubhead text={perk} noMargin={true} roadMapText={false} />
              )}
            </Box>
          </SimpleGrid>
        </AnimationOnScroll>
      ))}
    </Box>
  );
};

export default BulletLayout;
