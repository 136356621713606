import { Box, Image, Text } from "@chakra-ui/react";
import BulletLayout from "../UIComponents/BulletLayout";
import rightAngledLine from "../images/rightAngledLine.svg";
import leftAngledLine from "../images/leftAngledLine.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";

const TimelineItem = ({ data, left, responsive }) => (
  <Box position="relative">
    {responsive ? (
      <Box
        position="relative"
        width={responsive ? "100%" : "80%"}
        wordBreak="break-word"
        marginLeft={left ? "0%" : responsive ? "0%" : "25%"}
      >
        <Text
          color="white"
          fontSize={responsive ? "5vw" : "2vw"}
          fontWeight="bold"
        >
          {data.title[0]} - <span className="textGrad">{data.title[1]}</span>
        </Text>
      </Box>
    ) : (
      <AnimationOnScroll
        animateIn="animate__fadeIn"
        delay={500}
        duration={3}
        animateOnce={true}
      >
        <Box
          position="relative"
          width={responsive ? "100%" : "80%"}
          wordBreak="break-word"
          marginLeft={left ? "0%" : responsive ? "0%" : "25%"}
        >
          <Text
            color="white"
            fontSize={responsive ? "5vw" : "2vw"}
            fontWeight="bold"
          >
            {data.title[0]} - <span className="textGrad">{data.title[1]}</span>
          </Text>
        </Box>
      </AnimationOnScroll>
    )}
    {responsive ? null : (
      <AnimationOnScroll
        animateIn={left ? "animate__fadeInLeftBig" : "animate__fadeInRightBig"}
        animateOnce={true}
      >
        <Box>
          <Image
            src={left ? leftAngledLine : rightAngledLine}
            h="100%"
            w="100%"
            alt="angledLine"
            position="relative"
            marginTop="0.5vw"
            marginBottom="-4.5vw"
          />
          <Box
            h="60px"
            w="60px"
            position="absolute"
            right={left && "-33px"}
            left={!left && "-33px"}
            top="70%"
            bg="transparent"
            border="1px solid #48D6D2"
            borderRadius="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={10}
          >
            <Box
              h="40px"
              w="40px"
              position="absolute"
              bg="#48D6D2"
              borderRadius="full"
              zIndex={1}
            />
          </Box>
        </Box>
      </AnimationOnScroll>
    )}
    {responsive ? (
      <Box
        position="relative"
        marginLeft={left ? "0%" : responsive ? "0%" : "25%"}
        mt={responsive ? "5%" : "0%"}
      >
        <Box
          bg="rgba(255, 255, 255, 0.07);"
          py="0"
          pl="5"
          pr="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="5"
          border="1px solid #ffffff33"
          width={left ? "75%" : "100%"}
        >
          <BulletLayout
            items={data.texts}
            roadMapBullets={true}
            responsive={responsive}
          />
          <span className="circle" />
        </Box>
      </Box>
    ) : (
      <AnimationOnScroll
        animateIn="animate__fadeIn"
        delay={500}
        duration={3}
        animateOnce={true}
      >
        <Box
          position="relative"
          marginLeft={left ? "0%" : responsive ? "0%" : "25%"}
          mt={responsive ? "5%" : "0%"}
        >
          <Box
            bg="rgba(255, 255, 255, 0.07);"
            py="0"
            pl="5"
            pr="6"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="5"
            border="1px solid #ffffff33"
            width={left ? "75%" : "100%"}
          >
            <BulletLayout
              items={data.texts}
              roadMapBullets={true}
              responsive={responsive}
            />
            <span className="circle" />
          </Box>
        </Box>
      </AnimationOnScroll>
    )}
  </Box>
);

export default TimelineItem;
