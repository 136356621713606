import { Box } from "@chakra-ui/react";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Box color="white" py="28" px={["10", "10", "28"]}>
      <p>
        Our Terms of Use were posted on 5th March 2022
        <br />
        <br />
        Please read these terms and conditions carefully before using Our
        Services
        <br />
        <br />
        This document is an electronic record in terms of Information Technology
        Act, 2000 and published in accordance with the provisions of Rule 3) of
        the Information Technology (Intermediaries guidelines) Rules, 2011 that
        require publishing the rules and regulations, privacy policy and Terms
        of Use by FLURT network pvt. ltd. On their website FLURT.net
      </p>

      <p>Table of Contents</p>
      <br />
      <ul>
        <li>Introduction and User Guidelines</li>
        <br />
        <li>Registered Users</li>
        <br />
        <li>User1 Conduct</li>
        <br />
        <li>Content</li>
        <br />
        <li>Liability and Indemnification</li>
        <br />
        <li>Disclosure</li>
        <br />
        <li>Rights you grant to us</li>
        <br />
        <li>Intellectual Property Rights</li>
        <br />
        <li>Waiver</li>
        <br />
        <li>Arbitration</li>
        <br />
        <li>No Third-party beneficiaries</li>
        <br />
        <li>Non-transferability of accounts</li>
        <br />
        <li>Headings</li>
        <br />
        <li>Governing Law</li>
        <br />
        <li>For European Union (EU) Users</li>
        <br />
        <li>
          United States Legal Compliance
          <br />
          17.Modifications
        </li>
        <br />
        <li>Contact</li>
      </ul>

      <p>
        FLURT Network Private Limited (“Flurt”, “us”, “we” or “Company”) is a
        duly registered company providing trustable dating app using crypto,
        through its website having universal resource locator (ULR) -
        www.flurt.net (“flurt.net” or “Website”) and having its registered
        office at{" "}
        <strong>
          <em>
            <strong>_</strong>
          </em>
        </strong>{" "}
        . Any and all the content upload on the Website along with all the
        services provided by the Company to the users (“you”) of the Website are
        subject to the following Terms of Use in this document titled as “Terms
        of Use”.
        <br />
        <br />
        You, hereby, agree to be bound be the latest amended versions of this
        Terms of Use, Privacy Policy and Cookie Policy, which is a browse-wrap
        agreement between you and the Flurt Network. Furthermore, you agree to
        abide by any other terms and conditions as displayed or publicly
        announced on any relevant web-pages or media channels in any manner from
        time-to-time.
      </p>

      <p>
        Acknowledgment
        <br />
        <br />
        These are the Terms of Use governing the use of this Service and the
        agreement that operates between You and the Company. These Terms of Use
        set out the rights and obligations of all users regarding the use of the
        Service.
      </p>

      <p>
        Your access to and use of the Service is conditioned on Your acceptance
        of and compliance with these Terms of Use. These Terms of Use apply to
        all visitors, users and others who access or use the Service.
      </p>

      <p>
        By accessing or using the Service You agree to be bound by these Terms
        of Use. If You disagree with any part of these Terms of Use then You may
        not access the Service.
      </p>

      <p>
        You represent that you are over the age of 18. The Company does not
        permit those under 18 to use the Service.
      </p>

      <p>
        Your access to and use of the Service is also conditioned on Your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection,
        use and disclosure of Your personal information when You use the
        Application or the Website and tells You about Your privacy rights and
        how the law protects You. Please read Our Privacy Policy carefully
        before using Our Service.
      </p>
      <br />
      <ol>
        <li>
          Introduction and User Guidelines
          <br />
          <br />
          Flurt Network displays its app features, story, and details of how our
          services are different and unique from other dating websites, on it’s
          Website for users to get to know the FLURT vision.
          <br />
          By using the information, feature(s), functionality, service(s),
          product(s) or any other data located on www.flurt.net, through any
          service provide by the Company, or through any agent of Flurt Network,
          or any other website(s) which is working in association with the
          Company, or any other person (either natural or juristic) recognized
          and authorized by the Company (collectively the “Service”), you agree
          to be bound by this Agreement, whether you are a “Visitor” (meaning
          you merely browse the Website) or you are a “Registered User” (meaning
          you have registered on yourself on the Website for availing our
          services).
          <br />
          The term “you” or “user” refers to a visitor or a registered User. If
          you wish to become a Registered User and utilise the Service you must
          indicate your acceptance of this Terms of Use during the registration
          process on the Website. If you accept this Agreement, you represent
          that you have the capacity to be bound by it or, if you are acting on
          behalf of a company or entity, that you have the authority to bind
          such entity.
        </li>
        <br />
        <li>
          Registered Users
          <br />
          <br />A visitor of the Website becomes a Registered User when they
          register their personal details with us. See Privacy Policy for more
          info on the data collected during the registration.
          <br />
          Only Registered Users are provided with the Service.
          <br />
          All the Registered Users are deemed to have agree to abide by all the
          Terms of Service laid down in this Agreement, as indicated by them
          during the registration process.
          <br />
          As a Registered User you shall be responsible for maintaining the
          confidentiality of your user ID and password i.e., login credentials
          for your account registered with us. Furthermore, you shall be
          completely and solely responsible for all activities that occur
          through the use of account registered with us as you agree that the
          Company has no way to access your account to make purchases or
          payments through your account.
          <br />
          You agree that if you provide any information that is untrue,
          inaccurate, not current or incomplete, we may indefinitely suspend or
          terminate or block access to the Service(s).
          <br />
          All the information provided by you for the afore-mentioned user
          registration will be subject to all other policies referred to in this
          Terms of Use.
        </li>
        <br />
        <li>
          User Conduct
          <br />
          <br />
          You agree that your use of the Website is strictly governed by the
          following terms along with all other applicable relevant laws and
          bye-laws:
          <br />
          <br />
          a. You shall not host, display, upload, modify, publish, transmit,
          update or share any information or data which:
          <br />
          <br />
          i. belongs to another person or entity, and over which you do not
          possess any right to share
          <br />
          <br />
          ii. is harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, paedophilic, libellous, infringes the privacy or
          confidentiality of any other person or entity, hateful, objectionable,
          disparaging, promoting anything otherwise unlawful or illegal in any
          manner whatsoever
          <br />
          <br />
          iii. is misleading or fraudulent in nature in any manner
          <br />
          <br />
          iv. comprises in any manner the transmission of junk mail, chain
          letters, or unsolicited mass mailing or spamming
          <br />
          <br />
          v. promotes, incites or encourages illegal activities, practices or
          conducts which is abusive, threatening, obscene, defamatory, or
          unlawful in any manner
          <br />
          <br />
          vi. infringes upon or violates any third-party's rights,
          confidentiality, or privacy including, but not limited to,
          intellectual property rights, rights of privacy (including without
          limitation unauthorized disclosure of a person's name, email address,
          physical address or phone number) or rights of publicity
          <br />
          <br />
          vii. contains restricted or password-only access pages, or hidden
          pages or images (those not linked to or from another accessible page)
          <br />
          <br />
          viii. provides instructional information about illegal activities such
          as making or buying illegal weapons, violating someone's privacy, or
          providing or creating computer viruses
          <br />
          <br />
          ix. contains video, photographs, or images of another person (with a
          minor or an adult).
          <br />
          <br />
          x. attempts to gain unauthorized access or exceeds the scope of
          authorized access to the Website or to profiles, blogs, communities,
          account information, bulletins, friend request, or other web-pages,
          sections or tabs of the Website or solicits passwords or personal
          identifying information for commercial or unlawful purposes from other
          users
          <br />
          <br />
          xi. interferes with use and enjoyment of the Website or Service or any
          other engagement with the Company
          <br />
          <br />
          xii. infringes any patent, trademark, copyright or other proprietary
          rights or third-party's trade secrets or rights of publicity or
          privacy or is fraudulent or involves the sale of counterfeit or stolen
          products/consumables/edibles.
          <br />
          <br />
          xiii. violates any law for the time being in force or promotes such
          commission or omission
          <br />
          <br />
          xiv. threatens the unity, integrity, defence, security or sovereignty
          of India, friendly relations with foreign states, or public order or
          causes incitement to the commission of any cognizable offence or
          prevents investigation of any offence or is insulting any other nation
          <br />
          <br />
          xv. shall not be false, inaccurate or misleading
          <br />
          <br />
          b. shall not create liability for us or cause us to lose, neither in
          whole nor in part, the services of our internet service provider (ISP)
          or other suppliers or intermediary or facilitator of our Service
          <br />
          <br />
          c. A user may be considered malicious or loss to business due to
          fraudulent, deceiving, threatening, scamming, cheating activity if any
          of the user:
          <br />
          <br />
          i. Does not reply to any payment verification mail sent by us
          <br />
          ii. Fails to produce required documents during the payment details
          verification
          <br />
          <br />
          iii. Misuses of another user’s phone number or e-mail ID
          <br />
          <br />
          iv. Uses temporary e-mail ID, fake address, email ID or phone number
          <br />
          <br />
          v. Abuses of vouchers or coupons
          <br />
          <br />
          vi. Uses special vouchers not tagged to the email ID of the user.
          <br />
          <br />
          vii. Return wrong, different or altered product in cases of changes,
          returns or refunds
          <br />
          <br />
          viii. Refuses to pay for an order
          <br />
          <br />
          ix. Is identified, caught, blacklisted or suspected in snatch and run
          of any order
          <br />
          <br />
          x. Engages in miscellaneous activities conducted with the sole
          intention to cause loss to business/revenue to the Company
          <br />
          <br />
          xi. Has a very high return rate or refunds as per internal statistics
          <br />
          <br />
          xii. Repeatedly requests for monetary compensation for fake/used order
          <br />
          <br />
          d. We may cancel any order that classify as 'Bulk Orders' or ‘Fraud
          Orders' under certain criteria at any stage of the product delivery.
          An order can be classified as 'Bulk Order' or 'Fraud Order' if it
          meets with the below mentioned criteria, and any additional criteria
          as defined by us:
          <br />
          <br />
          i. Product(s) ordered are not for self-consumption but for commercial
          resale
          <br />
          <br />
          ii. Multiple orders placed for same product at the same address,
          depending on the product category.
          <br />
          <br />
          iii. Bulk quantity of the same product ordered
          <br />
          <br />
          iv. Invalid address given in order details
          <br />
          <br />
          v. Any malpractice used to place the order
          <br />
          <br />
          vi. Any promotional voucher used for placing the 'Bulk Order' may not
          be refunded
          <br />
          <br />
          vii. Any order paced using a technological glitch/loophole.
        </li>
      </ol>
      <br />
      <p>
        e. You shall not use the Website for any unlawful and fraudulent
        purposes, which may cause annoyance and inconvenience and abuses any
        policy and rules of the company and interrupt or causes to interrupt,
        damages the use by other users.
      </p>
      <br />
      <p>
        f. You shall not use any false or temporary e-mail address, impersonates
        any person or entity, or otherwise mislead us by sharing multiple
        address and phone numbers or transacting with malafide intentions.
      </p>
      <br />
      <p>
        g. We on certain web-pages allow our users to experience free exchange
        of ideas, comments and observations regarding interest in the field of
        fashion or reviews by other users which includes but is not limited to
        viewing user generated content, videos, images, audio and posting
        comments. By accessing, viewing or posting any user generated content to
        any specific dedicated web-page owned by us, you accept and consent to
        the practices described in these ‘Terms of Service’ and ‘Privacy
        Policies’, as well as any other terms of prescribed by the us on the
        Website. You agree and undertake that when accessing, viewing or posting
        any user generated content on these pages you will not imitate, abuse,
        harass, any user or violate and exploit, any the‘Terms of Service’ of
        the Website.
      </p>
      <br />
      <p>
        h. You must not use any "deep-link", "page-scrape", "robot", "spider" or
        other automatic device, program, algorithm or methodology, or any
        similar or equivalent manual process, to access, acquire, copy or
        monitor any portion of the Website or any Content, or in any way
        reproduce or circumvent the navigational structure or presentation of
        the Website or any content, to obtain or attempt to obtain any
        materials, documents, data or information by any means not purposely
        made available through the Website. We reserve our right to bar any such
        activity.
      </p>
      <br />
      <p>
        i. You must not attempt to gain unauthorized access to any portion or
        feature of the Website, or any other systems or networks connected to it
        or to any server, computer, network, or to any Service offered by us, by
        hacking, password mining, brute-force or any other illegitimate means,
        mode or strategic procedure.
      </p>
      <br />
      <p>
        j. You must not pretend that you are, or that you represent, someone
        else, or impersonate any other individual or entity
        <br />
        k. You shall at all times ensure full compliance with the applicable
        provisions of the Information Technology Act, 2000 and rules thereunder
        as applicable and as amended from time-to-time and also all applicable
        domestic laws, rules and regulations (including the provisions of any
        applicable Exchange Control Laws or regulations in force) and
        international laws, foreign exchange laws, statutes, ordinances and
        regulations (including, but not limited to sales tax/VAT, income tax,
        octroi, service tax, central excise, custom duty, local levies)
        regarding your use of our service and your listing, purchase,
        solicitation of offers to purchase, and sale of products or services.
        You must not engage in any transaction in an item or service, which is
        prohibited by the provisions of any applicable law including exchange
        control laws or regulations for the time being in force.
      </p>
      <br />
      <p>
        l. From time to time, you shall be responsible for providing information
        relating to the products or services proposed to be sold by you. In this
        connection, you undertake that all such information shall be accurate in
        all respects. You shall not exaggerate or over emphasize the attributes
        of such products or services so as to mislead other users in any manner.
      </p>
      <br />
      <p>
        m. You must not engage in advertising to, or solicitation of, other
        users to buy or sell any products or services, including, but not
        limited to, products or services related to that being displayed on the
        Website or related to us.
        <br />
        n. Any content posted does not necessarily reflect our views. In no
        event whatsoever, we must not be assumed to have or have any
        responsibility or liability for any content posted or for any claims,
        damages or losses resulting from use of content or appearance of content
        on any website, web-page, social media account/page, blogpost, comment,
        video, audio, graphics owned by us. You, hereby, represent and warrant
        that you have all necessary rights in and to all content which you
        provide and all information it contains, along with all such content
        does not infringe any proprietary or other rights of any third-parties
        or contain any libellous, tortious, or otherwise unlawful information.
      </p>
      <br />
      <ol start="4">
        <li>
          Content
          <br />
          <br />
          You may use information on the products and services purposely made
          available by us for downloading, provided that you:
          <br />
          <br />
          i. do not remove any proprietary notice language in all copies of such
          documents
          <br />
          <br />
          ii. use such information only for your personal, non-commercial
          informational purpose and do not copy or post such information on any
          networked computer or broadcast it in any media
          <br />
          <br />
          iii. make no modifications to any such information
          <br />
          <br />
          iv. do not make any additional representations or warranties relating
          to such documents
        </li>
        <br />
        <li>
          Liability and Indemnification
          <br />
          <br />
          We are not liable to indemnify you or any other Registered User for
          any kind of loss or damage caused to you or any other Registered User
          during any interaction, transaction, dealing, dispute or purchase from
          us.
          <br />
          <br />
          You agree to indemnify and hold the Company, and its subsidiaries,
          affiliates, officers, agents, co-branders or other partners and
          employees harmless from any claim or demand, including reasonable
          attorneys’ fees, arising out of your use of the services provided by
          us, your connection to the services provided by us or your violation
          of the Terms of Use as laid down in this agreement.
        </li>
        <br />
        <li>
          Disclosure
          <br />
          <br />
          You agree that we may electronically verify the data shared with us by
          various means such as one time password (OTP) verification, e-mail
          verification or both at various stages of your interaction with the
          Company. This verification process is guided by the standard industry
          practices and internal policies for user safety.
          <br />
          <br />
          You agree that we have the sole right to remove, delete or temporarily
          take down or suspend or permanently block access to the Website, your
          registered user account without any disclosure or prior notice.
          <br />
          <br />
          We are not liable to disclose the details of any other Registered User
          to you or any private individual or any private body corporate. We
          take no responsibility or liability of any loss suffered to any
          Registered User due to the disclosure of any kind of data or
          information related to the said Registered User under any order or
          direction of either the Central Government or any State Government of
          India or any other body/agency/organization authorized by the virtue
          of any law in force at that point in time.
        </li>
        <br />
        <li>
          Rights you grant to us
          <br />
          <br />
          You acknowledge and agree that, the information provided by you
          including but not limited to – your name, gender, sexual orientation,
          ethnicity, address, contact details and preferences while using the
          Website, may be used to improve user interface (UI) and user
          experience (UX) along with but not limited to marketing and sales
          purposes.
        </li>
        <br />
        <li>
          Intellectual Property Rights
          <br />
          <br />
          All content included or displayed or uploaded on the Website,
          including but not limited to texts, graphics, logos, button icons,
          images, audio clips, digital downloads and data compilations, as well
          as the compilation of that content into a single coherent website or a
          collection web-pages and other internet resources, are the property of
          the Company and protected by Indian and international intellectual
          property laws. Reproduction of the content of www.flurt.net without
          the written and express permission of the Company is prohibited.
          <br />
          <br />
          Stringent legal action will be taken against any and all person(s),
          including both natural and juristic, for infringement of intellectual
          property rights owned by te Company.
          <br />
          <br />
          Flurt Network Pvt Ltd, FLurt logo and other graphics, logos and
          service names are trademarks, certification marks, service marks or
          mascots, or other trade dress of the Company. Our trademarks,
          certification marks, service marks, and trade dress have inherent
          meaning and substantial value because of their restricted use. They
          may not be used in connection with any product or service that is not
          owner, created or published by the Flurt Network Pvt Ltd, in any
          manner without our permission. All other trademarks not owned by us or
          our subsidiaries that may appear on the Website are the property of
          their respective owners, who may or may not be affiliated with,
          connected to, or sponsored by us i.e. Flurt or our subsidiaries.
        </li>
        <br />
        <li>
          Waiver
          <br />
          <br />
          The failure of the Company to exercise or enforce any right or
          provision of this Agreement shall not constitute a waiver of such
          right or provision. If any provision of the Terms of Use is found by a
          court of competent jurisdiction to be invalid, the parties
          nevertheless agree that the court should endeavour to give effect to
          the parties’ intentions as reflected in the provision, and the other
          provisions of the Terms of Use remain in full force and effect.
        </li>
        <br />
        <li>
          Arbitration
          <br />
          <br />
          In the case of any disputes under this Agreement, the parties shall
          first attempt in good faith to resolve their dispute informally, or by
          means of commercial mediation, without the necessity of a formal
          proceeding. Any controversy or dispute arising out of or relating to
          this Agreement, or the breach thereof, which cannot otherwise be
          resolved as provided above shall be resolved by arbitration conducted
          in accordance with the arbitration rules of the Arbitration and
          Conciliation Act, 1996. The arbitration tribunal shall consist of a
          single arbitrator mutually agreed by the parties, or in the absence of
          such agreement within thirty calendar days from the first referral of
          the dispute to the tribunal. The place of arbitration shall be Delhi,
          India, unless the parties shall have agreed to another location within
          fifteen (15) calendar days from the first referral of the dispute to
          the tribunal. The arbitral award shall be final and binding. The
          parties waive any right to appeal the arbitral award, to the extent a
          right to appeal may be lawfully waived. Each party retains the right
          to seek judicial assistance: (i) to compel arbitration; (ii) to obtain
          interim measures of protection prior to or pending arbitration, (iii)
          to seek injunctive relief in the courts of any jurisdiction as may be
          necessary and appropriate to protect the unauthorized disclosure of
          its proprietary or confidential information, and (iv) to enforce any
          decision of the arbitrator, including the final award. The arbitration
          proceedings contemplated by this paragraph shall be as confidential
          and private as permitted by law. To that end, the parties shall not
          disclose the existence, content or results of any proceedings
          conducted in accordance with this Section, and materials submitted in
          connection with such proceedings shall not be admissible in any other
          proceeding, provided, however, that this confidentiality provision
          shall not prevent a petition to vacate or enforce an arbitral award,
          and shall not bar disclosures have required by law.
        </li>
        <br />
        <li>
          No Third-party beneficiaries
          <br />
          <br />
          You agree that, except as otherwise expressly provided in this Terms
          of Use, there shall be no third-party beneficiaries to this Agreement.
        </li>
        <br />
        <li>
          Non-transferability of accounts
          <br />
          <br />
          You agree that your user account registered with the Company is
          non-transferable.
        </li>
        <br />
        <li>
          Headings
          <br />
          <br />
          The headings and section titles in the Terms of Use are for
          convenience only and have no legal or contractual effect.
        </li>
        <br />
        <li>
          Governing Law
          <br />
          <br />
          These terms shall be governed by and constructed in accordance with
          the laws of India without reference to conflict of laws principles and
          disputes arising in relation hereto, and are subject to the exclusive
          jurisdiction of courts, tribunals, fora, applicable authorities at
          Delhi, India. The place of jurisdiction shall be exclusively in Delhi,
          India.
        </li>
        <br />
        <li>
          For European Union (EU) Users
          <br />
          <br />
          If You are a European Union consumer, you will benefit from any
          mandatory provisions of the law of the country in which you are
          resident in.
        </li>
        <br />
        <li>United States Legal Compliance</li>
      </ol>
      <br />
      <p>
        You represent and warrant that (i) You are not located in a country that
        is subject to the United States government embargo, or that has been
        designated by the United States government as a "terrorist supporting"
        country, and (ii) You are not listed on any United States government
        list of prohibited or restricted parties.
      </p>
      <br />
      <ol start="17">
        <li>
          Modifications
          <br />
          <br />
          We may modify this Agreement from time to time. Any and all changes to
          this Agreement will be posted on the Website. In addition, the
          Agreement will indicate the date it was last revised. By continuing to
          access or use Our Service after those revisions become effective, You
          agree to be bound by the revised terms. If You do not agree to the new
          terms, in whole or in part, please stop using the website and the
          Service.
        </li>
        <br />
        <li>
          Contact
          <br />
          <br />
          You may contact us for any query related to the Terms of Use. You may
          use the below information to reach out to us for all communication
          purposes including but not limited to legal or grievance purposes:
          <br />
          <br />
          E-mail ID: support@flurt.net
          <br />
          <br />
          This is in accordance with the Information Technology Act 2000, and
          rules made there under along with the Consumer Protection (E-Commerce)
          Rules, 2020, therefore, name and contact details of the Grievance
          Officer is as the same as mentioned above.
        </li>
      </ol>
    </Box>
  );
};

export default PrivacyPolicy;
