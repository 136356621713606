import { Box } from "@chakra-ui/react";
import React from "react";
import FAQCard from "./FAQCard";

const FAQ = ({ faqs }) => {
  return (
    <Box>
      <Box mt="5">
        {faqs.map(({ q, a, type }) => (
          <FAQCard q={q} a={a} type={type} key={q} />
        ))}
      </Box>
    </Box>
  );
};

export default FAQ;
