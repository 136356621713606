import { Box } from "@chakra-ui/react";
import { React } from "react";
import { motion } from "framer-motion";

const UIButton = ({ text, includeMargin }) => {
  return (
    <Box
      mt={includeMargin ? "10" : "0"}
      fontSize={["1em", "1.2em", "1.2em"]}
      as={motion.div}
      drag="x"
      dragConstraints={{ left: -100, right: 100 }}
      whileHover={{ scale: 1 }}
      whileTap={{ scale: 0.9 }}
      transition="0.5s linear"
    >
      <button class="btn btn-gradient-border btn-glow   ">{text}</button>
    </Box>
  );
};

export default UIButton;
