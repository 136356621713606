import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import UIHeadingGradSameLine from "../UIComponents/UIHeadingGradSameLine";
import UISubhead from "../UIComponents/UISubhead";
import FAQ from "./FAQ";
import { AnimationOnScroll } from "react-animation-on-scroll";

const FAQSection = () => {
  const [currentItem, setcurrentItem] = useState("General");
  const generalFaqs = [
    {
      q: "What is Flurt?",
      a: "Flurt is a web3 based dating & networking app. Flurt promotes collaborations and meaningful connections through art, entertainment, music, games and other fun activities.",
    },
    {
      q: "How do I become a member of Flurt Network?",
      a: "To become a member, you need to either own a Flurt in You NFT (redeemed through Flurt Mint Pass) or you can simply hold $FLURT tokens to use soon to launch Flurt App.",
    },
    {
      q: "How to decide which NFT to buy?",
      type: "bullets",
      a: {
        title:
          "It depends on what you want from Flurt Network. We have recognized 3 types of needs by users:",
        desc: [
          "Networking & Dating: Flurt Mint Pass is the best choice for users who want to party, meet, play, create & collaborate in real life. Flurt Mint Passes are you used to mint custom Flurt in You NFTs in the Flurt App.",
          "Utility: Legacy NFTs is the best choice for users looking to reap more benefits (such as 2x Rewards, No Cooling Off, First in Queue etc.) by gaining certain powers from our Legacy Potions in the Flurt App.",
        ],
      },
    },
    {
      q: "What are Flurt tokens?",
      a: "Flurt tokens are based on the Matic blockchain and are extensively used on the Flurt Network. Only members owning atleast $30 worth of tokens at the time of their staking can join Queues in the Flurt App.",
    },
    {
      q: "How can I buy $FLURT tokens?",
      a: "Please, follow us on Twitter for such announcements - <a href='https://twitter.com/flurtnet' target='_blank'><b style='color: #00aff0;'>@Flurtnet</b></a>.",
    },
    {
      q: "How to decide which NFT to buy?",
      type: "bullets",
      a: {
        title: "Here are your options:",
        desc: [
          "Legacy NFTs: Limited Flurt potion NFTs (only 6 types available). Gives redeemable Flurt tokens and superpowers in Flurt App. NFT holders automatically become Flurt member and get 1000 $FLURT tokens (redeemable later).",
          "Flurt in You NFTs: NFTs that look like you. NFT holders automatically become Flurt members. Does not give redeemable Flurt tokens. More details to be announced.",
        ],
      },
    },
    {
      q: "Why should I become a member?",
      type: "bullets",
      a: {
        title: "Flurt is a networking platform for:",
        desc: [
          "People to network and find meaningful connections through our real-life events, app and metaverse.",
          "Members to connect, collaborate & partner up with other members.",
          "Artists, musicians and entertainers to gather support and showcase their talents.",
          "Access to premium lifestyle events, festivals, parties and boot camps.",
        ],
      },
    },
    {
      q: "What are Flurt tokens?",
      a: "Flurt tokens are based on the Matic blockchain and are extensively used on the Flurt Network.",
    },
    {
      q: "How can I buy $FLURT tokens?",
      a: "You can get $FLURT tokens by participating in our private sale at <a href='https://opensea.io/flurt' target='_blank'><b style='color: #00aff0;'>OpenSea</b></a> OR You can get $FLURT tokens at IDO launch (to be announced).",
    },
    {
      q: "What are Flurt evenings?",
      a: "Flurt evening are parties where members can meet, connect & collaborate with entrepreneurs, artists, professionals over blockchain and NFT, projects amongst many amazing individuals and teams. You can check out flurt evening <a href='https://www.youtube.com/watch?v=LnToWn-r0ms' target='_blank'>here</a>.",
    },
    {
      q: "When is the next Flurt Evening?",
      type: "bullets",
      a: {
        title: "",
        desc: [
          "Flurt Evening NYC: Next flurt evening scheduled on June 19th, 2022 at Lavan 541, 541 W 25th St, New York from 6 PM to 11 PM. Purchase a pass here <a href='https://www.eventbrite.com/e/flurt-evenings-x-nftnyc-tickets-362563827167?discount=Mohitisaflurt' target='_blank'><b style='color: #00aff0;'>here</b></a>.",
          "Flurt Evening Miami: To be announced",
          "Follow our <a href='https://twitter.com/flurtnet'><b style='color: #00aff0;'>Twitter</b></a> for upcoming flurt evening announcement.",
        ],
      },
    },
  ];

  return (
    <Box pt="10" pb="20" px="10%" textAlign="center">
      <AnimationOnScroll animateIn="animate__bounceIn" animateOnce={true}>
        <UIHeadingGradSameLine text="FAQ" />
      </AnimationOnScroll>

      <Box display="flex" justifyContent={["center", "center", "left"]}>
        {[
          "General",
          //"Token Sales"
        ].map((item) => (
          <Box key={item} onClick={() => setcurrentItem(item)} cursor="pointer">
            <Box px="4" py="2">
              <UISubhead
                text={item}
                onClick={() => setcurrentItem("General")}
              />
            </Box>
            <Box
              bg={
                currentItem === item
                  ? "linear-gradient(90.13deg, #C72891 -1.4%, #012ECE -1.39%, #C72891 100%)"
                  : "transparent"
              }
              w="100%"
              h="3px"
            />
          </Box>
        ))}
      </Box>
      <FAQ faqs={generalFaqs} />
    </Box>
  );
};

export default FAQSection;
