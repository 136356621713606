import { Box } from "@chakra-ui/react";
import React from "react";

const TnC = () => {
  return (
    <Box textColor="white" py="28" px={["10", "10", "28"]}>
      <p>
        *This website stores cookies. By accepting you are consenting for us to
        use this information in order to improve your browsing experience and
        for our internal analytics. To learn more, please see our Privacy
        Policy.
      </p>
      <br />
      <p>
        <strong>Our Commitment to You </strong>
        <strong></strong>
      </p>
      <br />
      <p>
        <strong>At FLURT,</strong> your privacy is a top priority. Your privacy
        is at the core of the way we design and build the services and products
        you know and love, so that you can fully trust them and focus on
        building meaningful connections.
      </p>
      <br />
      <p>
        We appreciate that you put your trust in us when you provide us with
        your information and we do not take this lightly.
      </p>
      <br />
      <p>
        <strong>Our commitment to privacy.</strong>&nbsp;We design all of our
        products and services with your privacy in mind. We involve experts from
        various fields, including legal, security, engineering, product design
        and others to make sure that our decisions are taken with the utmost
        respect for your privacy.
      </p>
      <br />
      <p>
        <strong>Our commitment to transparency.</strong>&nbsp;Because we use
        many of the same online services you do, we know that insufficient
        information and overly complicated language are common issues in privacy
        policies. We take the exact opposite approach: we’re doing our best to
        write our Privacy Policy and related documents in plain language. We
        actually want you to read our policies and understand our privacy
        practices!
      </p>
      <br />
      <p>
        <strong>Our commitment to security.</strong>&nbsp;We have teams
        dedicated to keeping your data safe and secure. We constantly update our
        security practices and invest in our security efforts to enhance the
        safety of your information.
      </p>
      <br />
      <p>
        This Privacy Policy applies from{" "}
        <strong>
          05<sup>th</sup> March, 2022
        </strong>
      </p>
      <br />
      <ol type="1">
        <li>
          <strong>Where this Privacy Policy Applies</strong>
          <strong></strong>
        </li>
      </ol>
      <br />
      <p>
        This Privacy Policy applies to websites, apps, events and other services
        we operate under the “FLURT” , “FLURT NETWORK”&nbsp; and its affiliates
        or subsidiaries. For simplicity, we refer to all of these as our
        “services” in this Privacy Policy.&nbsp;
      </p>
      <br />
      <ul>
        <li>
          <strong>Information We Collect</strong>
        </li>
      </ul>
      <br />
      <p>
        It goes without saying, we can’t help you develop meaningful connections
        without some information about you, such as basic profile details and
        the types of people you’d like to meet. We also collect information
        about your use of our services such as access logs, as well as
        information from third parties, like when you access our services
        through your social media account or when you upload information from
        your social media account to complete your profile.
      </p>
      <br />
      <p>
        <strong>Information you give us </strong>
        <strong></strong>
      </p>
      <br />
      <p>
        You choose to give us certain information when using our services. This
        includes:
      </p>
      <br />
      <ul>
        <li>
          When you create an account, you provide us with at least your phone
          number and email address, as well as some basic details necessary for
          the service to work, such as your gender and date of birth , and
          sexual orientation.
        </li>
        <li>
          When you complete your profile, you can share with us additional
          information, such as details on your bio, interests and other details
          about you, as well as content such as photos and videos. To add
          certain content, like pictures or videos, you may allow us to access
          your camera or photo album.
        </li>
        <li>
          When you subscribe to a paid service or make a purchase directly from
          us, you provide us or our payment service provider or Crypto Wallet
          and hosting platform, along with information, such as your debit or
          credit card number, Crypto used, and wallet ID and other financial
          information.
        </li>
        <li>
          When you participate in surveys, focus groups or market studies, you
          give us your insights into our products and services, responses to our
          questions and testimonials.
        </li>
        <li>
          When you choose to participate in our promotions, events or contests,
          we collect the information that you use to register or enter.
        </li>
        <li>
          If you contact our customer care team, we collect the information you
          give us during the interaction.
        </li>
        <li>
          If you share with us information about other people (for example, if
          you use contact details of a friend for a given feature), we process
          this information on your behalf in order to complete your request.
        </li>
        <li>
          Of course, we also process your chats with other members as well as
          the content you publish, as necessary for the operation of the
          services.
        </li>
      </ul>
      <br />
      <p>
        <strong>Information we receive from others</strong>
        <strong></strong>
      </p>
      <br />
      <p>
        In addition to the information, you may provide us directly, we receive
        information about you from others, including:
      </p>
      <br />
      <ul>
        <li>
          <strong>Members</strong>&nbsp;Members may provide information about
          you as they use our services, for instance as they interact with you
          or if they submit a report involving you.
        </li>
        <li>
          <strong>Social Media</strong>&nbsp;You may decide to share information
          with us through your social media account, for instance if you decide
          to create and log into your FLURT account via your social media or
          other account (e.g., Facebook, Google or Apple) or to upload onto our
          services information such as photos from one of your social media
          accounts (e.g., Instagram or Spotify).
        </li>
        <li>
          <strong>Other Partners</strong>&nbsp;We may receive information about
          you from our partners where FLURT and FLURT Network ads are published
          on a partner’s service (in which case they may pass along details on a
          campaign’s success). Where legally allowed, we can also receive
          information about suspected or convicted bad actors from third parties
          as part of our efforts to ensure our members’ safety and security.
        </li>
      </ul>
      <br />
      <p>
        <strong>Information collected when you use our services</strong>
        <strong></strong>
      </p>
      <br />
      <p>
        When you use our services, this generates technical data about which
        features you’ve used, how you’ve used them and the devices you use to
        access our services. See below for more details:
      </p>
      <br />
      <ul>
        <li>
          <strong>Usage Information</strong>&nbsp;Using the services generates
          data about your activity on our services, for instance how you use
          them (e.g., when you logged in, features you’ve been using, actions
          taken, information shown to you, referring webpages address and ads
          that you interacted with) and your interactions with other members
          (e.g., members you connect and interact with, when you exchanged with
          them, number of messages you send and receive).
        </li>
        <li>
          <strong>Device information</strong>&nbsp;We collect information from
          and about the device(s) you use to access our services, including
          hardware and software information such as IP address, device ID and
          type, apps settings and characteristics, app crashes, advertising IDs
          (which are randomly generated numbers that you can reset by going into
          your device’ settings), identifiers associated with cookies or other
          technologies that may uniquely identify a device or browser.
        </li>
        <li>
          <strong>Other information with your consent</strong>&nbsp;If you give
          us permission, we can collect your precise geolocation (latitude and
          longitude). The collection of your geolocation may occur in the
          background even when you aren’t using the services if the permission
          you gave us expressly permits such collection. If you decline
          permission for us to collect your precise geolocation, we will not
          collect it.&nbsp;
        </li>
        <li>
          <strong>
            Cookies and Other Similar Data Collection Technologies
          </strong>
        </li>
      </ul>
      <br />
      <p>
        We use and may allow others to use cookies and similar technologies
        (e.g., web beacons, pixels, SDKs) to recognize you and/or your
        device(s). You may read our Cookie Policy for more information on why we
        use them and how you can better control their use.
      </p>
      <br />
      <p>
        Some web browsers (including Safari, Internet Explorer, Firefox and
        Chrome) have a “Do Not Track” (“<strong>DNT</strong>”) feature that
        tells a website that a user does not want to have his or her online
        activity tracked. If a website that responds to a DNT signal receives a
        DNT signal, the browser can block that website from collecting certain
        information about the browser’s user. Not all browsers offer a DNT
        option and DNT signals are not yet uniform. For this reason, many
        businesses, including FLURT, do not currently respond to DNT signals.
      </p>
      <br />
      <ul>
        <li>
          <strong>How We Use Information</strong>
        </li>
      </ul>

      <p>
        The main reason we use your information is to deliver and improve our
        services. Additionally, we use your info to help keep you safe, and to
        provide you with advertising that may be of interest to you. Read on for
        a more detailed explanation of the various reasons for which we use your
        information, together with practical examples.
      </p>
      <br />
      <p>
        <strong>
          A. To administer your account and provide our services to you
        </strong>
      </p>
      <br />
      <ul>
        <li>Create and manage your account</li>
        <li>Provide you with customer support and respond to your requests</li>
        <li>Complete your transactions</li>
        <li>Communicate with you about our services</li>
      </ul>
      <br />
      <p>
        <strong>B. To help you connect with other users</strong>
      </p>
      <br />
      <ul>
        <li>Recommend you other members to meet</li>
        <li>Show members’ profiles to one another</li>
      </ul>
      <br />
      <p>
        <strong>C. To operate advertising and marketing campaigns</strong>
      </p>
      <br />
      <ul>
        <li>Administer sweepstakes, contests, discounts or other offers</li>
        <li>
          Perform and measure the effectiveness of advertising campaigns on our
          services and marketing campaigns promoting FLURT off our services
        </li>
        <li>
          Communicate with you about products or services that we believe may
          interest you
        </li>
      </ul>
      <br />
      <ul>
        <li>
          <strong>To improve our services and develop new ones</strong>
        </li>
      </ul>
      <br />
      <ul>
        <li>Administer focus groups, market studies and surveys</li>
        <li>
          Review interactions with customer care teams to improve our quality of
          service
        </li>
        <li>
          Understand how members typically use the services to improve them (for
          instance, we may decide to change the look and feel or even
          substantially modify a given feature based on how members react to it)
        </li>
        <li>
          Develop new features and services (for example, we may decide to build
          a new interests-based feature further to requests received from
          members)
        </li>
      </ul>
      <br />
      <p>
        <strong>
          E. To prevent, detect and fight fraud and other illegal or
          unauthorized activities
        </strong>
      </p>
      <br />
      <ul>
        <li>
          Find and address ongoing, suspected or alleged violations of
          our&nbsp;Terms of Use, notably through the review of reports and
          interactions between members
        </li>
        <li>
          Better understand and design countermeasures against violations of
          our&nbsp;Terms of Use
        </li>
        <li>
          Retain data related to violations of our&nbsp;Terms of Use&nbsp;to
          prevent against recurrences
        </li>
        <li>
          Enforce or exercise our rights, for example our&nbsp;Terms of Use
        </li>
        <li>
          Communicate to individuals who submit a report, what we’ve done as a
          result of their submission
        </li>
      </ul>
      <br />
      <p>
        <strong>F. To ensure legal compliance</strong>
      </p>
      <br />
      <ul>
        <li>Comply with legal requirements</li>
        <li>Assist law enforcement</li>
      </ul>
      <br />
      <p>
        To process your information as described in this Privacy Policy, we rely
        on the following legal bases:
      </p>
      <br />
      <ul>
        <li>
          <strong>
            <em>Provide our service to you:</em>
          </strong>
          &nbsp;The reason we process your information for purposes A and
          B&nbsp; above is to perform the contract that you have with us. For
          instance, as you go about using our service to build meaningful
          connections, we use your information to maintain your account and your
          profile, make it viewable to other members and recommend other members
          to you and to otherwise provide our free and paid features to you and
          other members.
        </li>
        <li>
          <strong>
            <em>Legitimate interests</em>
          </strong>
          <em>:</em>&nbsp;We process your information for purposes C, D and E
          above, based on our legitimate interest. For instance, we analyze
          users’ behavior on our services to continuously improve our offerings,
          we suggest offers we think might interest you and promote our own
          services, we process information to help keep our members safe and we
          process data where necessary to enforce our rights, assist law
          enforcement and enable us to defend ourselves in the event of a legal
          action.
        </li>
      </ul>
      <br />
      <ul>
        <li>
          <strong>
            <em>Comply with applicable laws and regulations:</em>
          </strong>
          &nbsp;We process your information for purpose F above where it is
          necessary for us to comply with applicable laws and regulations and
          evidence our compliance with applicable laws and regulations. For
          example, we retain traffic data and data about transactions in line
          with our accounting, tax and other statutory data retention
          obligations and to be able to respond to valid access requests from
          law enforcement. We also keep data evidencing consents members give us
          and decisions they may have taken to opt-out of a given feature or
          processing.
        </li>
        <li>
          <strong>
            <em>Consent</em>
          </strong>
          <em>:</em>&nbsp;If you choose to provide us with information that may
          be considered “special” or “sensitive” in certain jurisdictions, such
          as your sexual orientation, you’re consenting to our processing of
          that information in accordance with this Privacy Policy. From time to
          time, we may ask for your consent to collect specific information such
          as your precise geolocation or use your information for certain
          specific reasons. In some cases, you may withdraw your consent by
          adapting your settings (for instance in relation to the collection of
          our precise geolocation) or by deleting your content (for instance
          where you entered information in your profile that may be considered
          “special” or “sensitive”). In any case, you may withdraw your consent
          at any time by contacting us at the address provided at the end of
          this Privacy Policy.
        </li>
      </ul>
      <br />
      <ul>
        <li>
          <strong>How We Share Information</strong>
          <strong></strong>
        </li>
      </ul>
      <br />
      <p>
        Since our goal is to help you make meaningful connections, the main
        sharing of members’ information is, of course, with other members. We
        also share some members’ information with service providers and partners
        who assist us in operating the services, in some cases, legal
        authorities. Read on for more details about how your information is
        shared with others.
      </p>
      <br />
      <p>
        <strong>With other members</strong>
        <strong></strong>
      </p>
      <br />
      <ul>
        <li>
          You share information with other members when you voluntarily disclose
          information on the service (including your public profile). Please be
          careful with your information and make sure that the content you share
          is stuff that you’re comfortable being visible.
        </li>
        <li>
          If you choose to limit the audience for all or part of your profile or
          for certain content or information about you, then it will be visible
          according to your settings.
        </li>
        <li>
          If someone submits a report involving you (such as a claim you
          violated our&nbsp;Terms of Use), We may communicate to the reporter
          actions, if any, we took as a result of their report.
        </li>
      </ul>
      <br />
      <p>
        <strong>With our service providers and partners</strong>
        <strong></strong>
      </p>
      <br />
      <ul>
        <li>
          We use third parties to help us operate and improve our services.
          These third parties assist us with various tasks, including data
          hosting and maintenance, analytics, customer care, marketing,
          advertising, payment processing and security operations. We also share
          information with partners who distribute and assist us in advertising
          our services. For instance, we may share limited information on you in
          hashed, non-human readable form to advertising partners.
        </li>
        <li>
          We follow a strict vetting process prior to engaging any service
          provider or working with any partner. Our service providers and
          partners must agree to strict confidentiality obligations.
        </li>
      </ul>
      <br />
      <p>
        <strong>With our affiliates</strong>
      </p>
      <br />
      <p>FLURT or FLURT.net is the part of FLURT Network Private Limited.</p>
      <br />
      <p>
        We share your information with affiliates for limited legitimate
        purposes as laid out below:
      </p>
      <br />
      <ul>
        <li>
          to make all FLURT Network platforms safer and enable us to address
          (e.g., ban) bad actors found on one platform also on the others; for
          them to assist us in data processing operations, as service providers,
          upon our instructions and on our behalf. Their assistance may include
          technical processing operations, such as data hosting and maintenance,
          customer care, marketing and targeted advertising, analytics, finance
          and accounting assistance, improving our service, securing our data
          and systems and fighting against spam, abuse, fraud, infringement and
          other wrongdoings.
        </li>
        <li>
          We may also share information with other FLURT Network companies for
          other legitimate business purposes including corporate audit, analysis
          and consolidated reporting, where and as allowed under applicable law.
        </li>
      </ul>
      <br />
      <p>
        <strong>Sharing functionality</strong>
      </p>
      <br />
      <p>
        You may share other members’ profiles and they may share yours with
        people outside of our services, using the sharing functionality.
      </p>
      <br />
      <p>
        <strong>For corporate transactions</strong>
      </p>
      <br />
      <p>
        We may transfer your information if we are involved, whether in whole or
        in part, in a merger, sale, acquisition, divestiture, restructuring,
        reorganization, dissolution, bankruptcy or other change of ownership or
        control.
      </p>
      <br />
      <p>
        <strong>With law enforcement / when required by law</strong>
      </p>
      <br />
      <p>
        We may disclose your information if reasonably necessary: (i) to comply
        with a legal process, such as a court order, subpoena or search warrant,
        government / law enforcement investigation or other legal requirements;
        (ii) to assist in the prevention or detection of crime (subject in each
        case to applicable law); or (iii) to protect the safety of any person.
      </p>
      <br />
      <p>
        <strong>To enforce legal rights</strong>
      </p>
      <br />
      <p>
        We may also share information: (i) if disclosure would mitigate our
        liability in an actual or threatened lawsuit; (ii) as necessary to
        protect our legal rights and legal rights of our members, business
        partners or other interested parties; (iii) to enforce our agreements
        with you; and (iv) to investigate, prevent, or take other action
        regarding illegal activity, suspected fraud or other wrongdoing.
      </p>
      <br />
      <p>
        <strong>With your consent or at your request</strong>
      </p>
      <br />
      <p>
        We may ask for your consent to share your information with third
        parties. In any such case, we will make it clear why we want to share
        the information.
      </p>
      <br />
      <p>
        We may use and share non-personal information (meaning information that,
        by itself, does not identify who you are such as device information,
        general demographics, general behavioral data, geolocation in
        de-identified form), as well as personal information in hashed,
        non-human readable form, under any of the above circumstances. We may
        also share this information with other FLURT Network companies and third
        parties (notably advertisers) to develop and deliver targeted
        advertising on our services and on websites or applications of third
        parties, and to analyze and report on advertising you see. We may
        combine this information with additional non-personal information or
        personal information in hashed, non-human readable form collected from
        other sources. More information on our use of cookies and similar
        technologies can be found in our&nbsp;Cookie Policy.
      </p>
      <br />
      <ul>
        <li>
          <strong>What happens in the event of a change of control</strong>
          <strong></strong>
        </li>
      </ul>
      <br />
      <p>
        We may buy or sell/divest/transfer the company (including any shares in
        the company), or any combination of its products, services, assets
        and/or businesses. Your information such as customer names and email
        addresses, and other user information related to the services may be
        among the items sold or otherwise transferred in these types of
        transactions. We may also sell, assign or otherwise transfer such
        information in the course of corporate divestitures, mergers,
        acquisitions, bankruptcies, dissolutions, reorganizations, liquidations,
        similar transactions or proceedings involving all or a portion of the
        company, you will be notified via email and/or a prominent notice on the
        Website of any change in ownership or uses of your personal information,
        as well as any choices you may have regarding your personal information.
      </p>
      <br />
      <ul>
        <li>
          <strong>Children's Privacy</strong>
          <strong></strong>
        </li>
      </ul>
      <br />
      <p>
        Our services are restricted to individuals who are 18 years of age or
        older. We do not permit individuals under the age of 18 on our platform.
        If you suspect that a member is under the age of 18, please use the
        reporting mechanism available on the service.
      </p>
      <br />
      <ul>
        <li>
          <strong>Privacy Policy Changes</strong>
          <strong></strong>
        </li>
      </ul>
      <br />
      <p>
        Because we’re always looking for new and innovative ways to help you
        build meaningful connections and strive on making sure explanations
        about our data practices remain up-to-date, this policy may change over
        time. We will notify you before any material changes take effect so that
        you have time to review the changes
      </p>
      <br />
      <ul>
        <li>
          <strong>Links to other webpages</strong>
        </li>
      </ul>
      <br />
      <p>
        We are not responsible for the practices employed by websites or
        smart-phone applications or services linked to or from the Website,
        including the information or content contained therein. Please
        understand that when you use a link to go from Leadsharbor.com to
        another webpage or smart-phone app, our Privacy Policy does not apply to
        third-party websites or smart-phone apps or services. Your browsing and
        interaction on any third-party website or smart-phone app or service,
        including those that have a link or advertisement on our website, are
        subject to that third party’s own rules and policies.
      </p>
      <br />
      <ol type="1">
        <li>
          <strong>&nbsp; Social Media Widgets</strong>
          <strong></strong>
        </li>
      </ol>
      <br />
      <p>
        Our Web site includes Social Media Features. These Features may collect
        your IP address, which page you are visiting on our site, and may set a
        cookie to enable the Feature to function properly. Social Media Features
        and Widgets are either hosted by a third party or hosted directly on our
        Site. Your interactions with these Features are governed by the privacy
        policy of the company providing it.
      </p>
      <br />
      <p>
        If you have any questions about this Privacy Policy, the practices of
        this site, or your dealings with this website, please contact us at
        support @flurt.net.
      </p>
    </Box>
  );
};

export default TnC;
