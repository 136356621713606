import { Heading } from "@chakra-ui/react";
import React from "react";

const UIHeadingGrad = ({ text }) => {
  return (
    <Heading
      color="white"
      fontSize={["1.8em", "1.8em", "2.5em", "3em"]}
      className="textGrad"
      letterSpacing="1px"
    >
      {text}
    </Heading>
  );
};

export default UIHeadingGrad;
