import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import UIHeading from "../UIComponents/UIHeading";
import UIHeadingGradSameLine from "../UIComponents/UIHeadingGradSameLine";
import TimelineItem from "./TimelineItem";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

const Roadmap = () => {
  const items = [
    {
      title: ["Roadmap 1.0", "Flurt Events"],
      texts: [
        "Celebrated the 1st ever Flurt Day & Flurt Evening on 6th March, 2022",
        "Flurt Day attended by 300+ influential individuals",
        "Got Media Mentions & Instagram Virality",
        "#imaflurt Ice Breaker Game & Diamond Jewellery giveaway for the biggest Flurt of the Party",
      ],
    },
    {
      title: ["Roadmap 2.0", "Building with fellow flurts"],
      texts: [
        "Collaborated with artists, musicians & entertainers",
        "Toxic Skull Club 20x20ft Graffiti",
        "Flurt X Elements - Graffiti, Rap & Dance Battle",
        "Flurt X Toxic Skulls - #7663 Music Video (to be launched at NFT.NYC 2022)",
        "Flurt Evening in New York",
      ],
    },
    {
      title: ["Roadmap 3.0", "Collaborative spaces for flurts"],
      texts: [
        "Building a Flurt House in collaboration with M3M Real Estates",
        "Flurt App (under development)",
        "Flurt Metaverse (under development)",
      ],
    },
  ];

  function getCoords(elem) {
    // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }

  useEffect(() => {
    ["blue", "green", "red"].forEach((id) => {
      const elem = document.getElementById(id);
      const coords = getCoords(elem).top - 300;
      window.addEventListener("scroll", function () {
        if (window.scrollY >= coords) {
          const progressbar = document.getElementById("progressbar");
          const c0 = document.getElementById("c0");
          const c1 = document.getElementById("c1");
          const c2 = document.getElementById("c2");
          if (progressbar.style.height !== "88%") {
            switch (id) {
              case "blue":
                progressbar.style.height = "29%";
                c0.classList.add("active-roadmap");
                setTimeout(() => {
                  c0.style.opacity = "1";
                }, 2500);
                break;
              case "green":
                progressbar.style.height = "58%";
                c1.classList.add("active-roadmap");
                setTimeout(() => {
                  c1.style.opacity = "1";
                }, 2500);
                break;
              case "red":
                progressbar.style.height = "88%";
                c2.classList.add("active-roadmap");
                setTimeout(() => {
                  c2.style.opacity = "1";
                }, 2500);
                break;
              default:
                progressbar.style.height = "0%";
            }
          }
        }
      });
    });
  }, []);

  return (
    <Box position="relative" zIndex="10" overflow="hidden">
      <AnimationOnScroll animateIn="animate__bounceIn" animateOnce={true}>
        <Box justifyContent="center" display="flex">
          <UIHeading text="OUR " />
          <Box mx="1" />

          <UIHeadingGradSameLine text="ROADMAP" />
        </Box>
      </AnimationOnScroll>

      <Box px="10%" mt="5" display={["none", "none", "block"]}>
        <Box>
          {items.map((data, idx) => (
            <SimpleGrid
              templateColumns="50% 5px 50%"
              key={idx}
              height="55vh"
              id={idx === 0 ? "blue" : idx === 1 ? "green" : "red"}
            >
              {idx % 2 === 0 ? (
                <>
                  <Box my="20" zIndex={30}>
                    <TimelineItem data={data} key={idx} left={true} />
                  </Box>
                </>
              ) : (
                <div></div>
              )}

              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  w="5px"
                  h="0%"
                  bg="linear-gradient(#00000000, #C72891)"
                  id="progressbar"
                  position="absolute"
                  top="20"
                  zIndex={["0"]}
                />
              </Box>

              {idx % 2 === 0 ? (
                <Box />
              ) : (
                <Box>
                  <Box zIndex={20}>
                    <TimelineItem
                      data={data}
                      key={idx}
                      left={false}
                      responsive={false}
                    />
                  </Box>
                </Box>
              )}
            </SimpleGrid>
          ))}
        </Box>
      </Box>

      <Box px="4%" mt="5" display={["block", "block", "none"]}>
        <Box>
          {items.map((data, idx) => (
            <SimpleGrid
              templateColumns="auto auto"
              key={idx}
              spacing={7}
              ml="3"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={idx === 0 ? "55vh" : idx === 1 ? "70vh" : "50vh"}
              >
                {idx === 0 ? (
                  <Box
                    w="5px"
                    h="100%"
                    bg="linear-gradient(#00000000, #012ECE, #C72891 )"
                  />
                ) : idx === items.length - 1 ? (
                  <Box
                    w="5px"
                    h="100%"
                    bg="linear-gradient(#C72891, #012ECE, #00000000)"
                  />
                ) : (
                  <Box
                    w="5px"
                    h="100%"
                    bg="linear-gradient(#C72891, #012ECE, #C72891 )"
                  />
                )}
                <Box
                  h="35px"
                  w="35px"
                  position="absolute"
                  bg="transparent"
                  border="1px solid #48D6D2"
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    h="20px"
                    w="20px"
                    position="absolute"
                    bg="#48D6D2"
                    borderRadius="full"
                  />
                </Box>
              </Box>

              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                duration={1}
              >
                <Box
                  my={["5", "8", "10"]}
                  mt={idx === 0 ? "2" : idx === 1 ? "6" : "4"}
                >
                  <TimelineItem data={data} key={idx} responsive={true} />
                </Box>
              </AnimationOnScroll>
            </SimpleGrid>
          ))}
        </Box>
      </Box>

      <Box textAlign="center" mt="40">
        <Text
          color="white"
          fontSize={["xl", "xl", "2xl"]}
          pb="10"
          fontWeight={600}
          ml={["5", "0", "0"]}
          mt={window.screen.width > 768 ? "20" : "-40"}
          letterSpacing="0.3"
        >
          And many more to come
        </Text>
      </Box>
    </Box>
  );
};

export default Roadmap;
